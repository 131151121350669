*{
    --checkbox-wrap-height: 25px;
    --checkbox-wrap-width: 200px;
}

.normal-input-hints{
    font-size: 0.8rem;
    color: #373d4a;
    margin-top: 4px;
}
#address1, #address2, #address3{
    flex: unset;
    width: 70%;
}
.step1 #step1-card{
    width: 400px;
    height: 230px;
    border: 1px solid rgba(0,0,0,.12);
}
@media screen and (max-width: 633px) {
    .step1 #step1-card{
        width: 300px;
    }
}
.step1{
    /* height: -moz-calc(80vh);
    height: -webkit-calc(80vh);
    height: calc(80vh); */
    overflow-y: scroll;
}
.basic-info-form-body{
    overflow-y: scroll;
}
.checkbox-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    row-gap: var(--xs);
}
.checkbox-wrap{
    display: flex;
    column-gap: var(--xs);
    margin-right: var(--xxs);
    margin-left: var(--xxs);
    height: 25px;
    width: 100%;
    place-items: center;
}
.checkbox-wrap h6{
    line-height: unset;
    font-size: 0.9rem;
}
.checkbox{
    height: 14px;
    width: 14px;
}
.checkbox-delete-btn, .checkbox-add-btn{
    display: grid;
    place-items: center;
    font-size: 21px;
}
.checkbox-input-field{
    /* margin-top: var(--xxs); */
    width: 150px;
    height: 25px;
    padding: 12px 10px;
    /* margin: 8px 0; */
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
