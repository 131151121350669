.privacy{
    max-width:1000px;
    margin: auto;
    padding: 20px;
}
.privacy h1{
    margin: 20px 0;
    text-align: center;
    font-size: 2rem;
    color: black;
}
.privacy h2{
    margin: 30px 0;
    font-size: 1.5rem;
    color: black;
}
.privacy p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8rem;
    
}
.privacy li p{
    position: relative;
    left: 5px;
}
.privacy a{
    text-decoration: underline;
}