.app-login{
    width: 100vw; 
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-body{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 960px;
  padding-bottom: 20px;
}
header.login {
  position: absolute;
  display: flex;
  align-items: center;
  top:0;
  left: 0;
  padding-left: 50px;
  height: 84px;
  /* line-height: 84px; */
}
.company-logo{
  width: fit-content;
  height: 80px;
}
footer.login{
  position: fixed;
  left: 0;
  bottom: 0;
}

.login-header h2{
  font-weight: bold;
  /* color: black; */
  font-family: 'Montserrat', sans-serif;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.login-card{
  /* height: 600px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-color: thistle; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 480px;
}
.login-card-left{
  background-color: #FFFFFF;
  display: flex;
  width:50%;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
}
.login-card-right{
  width:50%;

  background-color: #abe9cd;
  background: -webkit-linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);
  background-image: linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);

  /* background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  padding: 0 50px;
  text-align: center;
  /* transform: translateX(0);
  transition: transform 0.6s ease-in-out; */
}
.login-card-right h1, .login-card-right h6{
  font-weight: bold;
  color: #eee;
  font-family: 'Montserrat', sans-serif;
}
.login-card-right h1{
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
.login-card-title{
  color: black;
  font-weight: bold;
  margin: 0;
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}
.login-input-field{
  border-radius: var(--border-radius);
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin-top: 8px;
  width: 100%;
}
.login-input-error{
  align-self: flex-start;
  height: 22px;
}
.login-btn{
  height: 43px;
  min-width: 154px;
  position: relative;
  border-radius: 20px;
  border: 1px solid #3eadcf;
  background-color: #3eadcf;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 60px;
  margin-top: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
#send-email-btn:disabled{
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  border: unset;
  cursor: default;

}
.signup-btn{
  height: 43px;
  min-width: 154px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 60px;
  margin-top: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}
.link-wrap{
  margin: 14px 0;
}
.login-card-link{
  display: inline;
  color: #333;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}
.login-btn:active, .signup-btn:active {
	transform: scale(0.95);
}

.register-link:hover{
  color: rgb(69, 69, 207)
}
.register-link:active{
  color: rgb(0, 0, 121);
}