#terms-and-conditions{
    height: 90vh;
    width: 600px;
    border: 1px solid grey;
}

#terms-content{
    /* background-color: pink; */
    
    overflow-y: scroll;
    border-radius: unset;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
#terms-content span{
    font-size: 0.8rem;
}
#web-terms-confirmation{
    margin: 0px;
    margin-right: 5px;
}