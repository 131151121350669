.credit-card-input-wrap{
    width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.select-plan-btn{
    place-self: center;
    width: 400px;
    /* margin-bottom: 25px; */
}
.show-plan-wrap{
    margin-bottom: 25px;
    gap: 40px;
    flex-direction: row-reverse;
}
.plan-card{
    width:300px;
    min-height: 180px;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    /* box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075); */
    border-radius: var(--sm);
    transition: box-shadow .3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    cursor: pointer;
    padding: var(--sm);
}
.plan-card:hover{
    box-shadow: rgba(99, 99, 99, 0.2) 6px 6px 6px 2px;
}
.plan-card:active{
    transform: scale(0.95);
}
.plan-card.chosen{
    background-color: #caffe7;
    background-image: linear-gradient(315deg, #b4ffddb2 0%, #7ce0ffd0 74%);
    /* color: rgb(238, 238, 238); */
    font-weight: bold;
}
.plan-card.chosen h6, .plan-card.chosen p{
    /* color: rgb(238, 238, 238); */
}
.plan-card .title{
    font-size: 1.2rem;
    line-height: 3rem;
}
/* .plan-card:hover > .plan-card-title{
    background-color: teal;
}
.plan-card:hover > .plan-card-title h6{
    color: #ffffff;
} */

.plan-card i{
    font-size: 2.5rem;
}
.plan-card p{
    font-size: 1rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0.2rem 0; */
}
.plan-card p>i{
    font-size: 1rem;
    margin-left: 0.3rem;
    line-height: 2rem;
    vertical-align: middle;
}
.plan-card-price{
    margin: 10px 0;
}
.plan-card-price h6{
    font-family: "Lato",sans-serif;
    letter-spacing: -2px;
    color: #525252;
    font-weight: 400;
    font-size: 1.4rem;
}

.plan-card .dollar-sign {
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 15px;
    font-weight: 500;
    vertical-align: top;
}
.plan-card .currency {
    font-family: "Lato",sans-serif;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 36px;
    padding: 0;
    vertical-align: middle;

}
.plan-card .highlight{
    color: #26a5ccd0;
}
.plan-card.chosen .highlight{
    color: unset;
}
.plan-card .year, .month, .interval{
    font-family: "Lato",sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
}
.pricing h2{
    color: #9A1452;
}
.plan-card-btn{
    margin: 20px 0;
    height: 50px;
    width: 150px;
    border-radius: var(--sm);
    background-color: #7f608b;
    color: white;
    font-size: 1.8rem;
}
.step2 a{
    text-decoration: underline;
    cursor: pointer;
}