.contact-case-wrap{
    margin-top: var(--sm);
    padding-top: var(--md);
    border-top: 1px solid rgb(194, 194, 194);
}
.contact-case-row{
    display: grid;
    /* grid-template-columns: 55% 30% 1fr; */
    grid-template-columns: 80% 1fr;
    column-gap: var(--sm);
}
.contact-case-row h6{
    font-size: 0.8rem;
}
.contact-case-card{
    display: flex;
    width: 100%;
    min-height: unset;
    height: 40px;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
    /* box-shadow: 0 2px 6px 0 #434e57; */
    transition: 0.3s;
    border-radius: 0.4rem;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--sm);
    padding-right: var(--sm);
}

.contact-case-card:hover{
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}
/* .date{
    font-family: "Poppins", monospace;
} */