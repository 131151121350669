.user-setting{
    width: 100vw; 
    height: 100vh;
}
.user-setting-appbar{
    position: fixed;
    height: 50px;
    background-color: #1a73e8;
    top: 0;
    right: 0;
}

.user-setting-body{
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 960px) {
    .user-setting-body{
        max-width: 800px;
    }
} 
@media screen and (max-width: 800px) {
    .user-setting-body{
        max-width: 90%;
    }
}
@media screen and (max-width: 575px) {
    .user-setting-body{
        min-width: 98%;
        /* margin-left: 20px;
        margin-right: 20px; */
    }
} 
.user-setting .mat-card{
    width:100%;
    height: unset;
}
.user-setting .mat-card .mat-card-head{
    height: 40px;
}
.user-setting .mat-card label{
    font-size: 0.9rem;
    line-height: 1.3rem;
    color: rgba(0,0,0,.54);
}
.user-setting .mat-card .input-field{
    flex: 3;
    padding: 0px;
}

.confirm-login-wrap {
    border-radius: 16px;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
}

.inline-btn{
    width:200px;
    /* height: 50px; */
}