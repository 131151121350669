.restaurant-card{
    width: 100%;
    /* height: 350px; */
    flex-basis: 200px;
    /* max-height: 100%; */
    position: relative;
    background-color: white;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
    /* box-shadow: 0 2px 6px 0 #434e57; */
    transition: 0.3s;
    border-radius: var(--border-radius);
}
.restaurant-card h6{
  font-size: 0.8rem;
}
.restaurant-card-icon{
  font-size: 1.3rem;
}
.restaurant-edit-btn{
  /* margin-top: 4px; */
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
}

.earth-btn{
  display: grid;
  /* justify-items: center; */
  align-items: center;
}
.createdAt{
  font-size: 0.7rem;
  color: grey;
}
.card-restaurant-name{
  word-wrap:break-word;
}

.card-info-row{
  /* display: grid;
  grid-template-columns: auto minmax(0, 1fr); */
  display: grid;
  grid-template-columns: calc(50% - 30px) 1fr 30px;
}
.stats-info-row{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: var(--xs);
}
.stats-info-row.isDemo{
  grid-template-columns: 2fr 1fr;
}
.date-picker{
  height: 40px;
  display: grid;
  grid-template-columns: 40px 120px 120px 120px 40px;
  column-gap: var(--xs);
  align-items: center;
}
.date-picker-btn{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background-color: thistle; */
}
.date-picker-btn:hover{
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
}
.date-btn{
  border: 1px solid rgb(32, 32, 32);
}

.date-type-btn{
  position: absolute;
  opacity: 0;
}
.date-type-label{
  /* align-self: center; */
  justify-self: center;
  width: 120px;
  height: 40px;
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 2px solid rgb(197, 229, 255);
  color: black;
  font-size: 0.8rem;
  text-align: center;
  /* vertical-align: middle; */
  display: flex;
  /* grid-template-columns: 30% 1fr; */
  justify-content: space-evenly;
  place-items: center;
  overflow-y: hidden;
}

.date-label h6{
  display: grid;
  grid-template-rows: 30% 1fr;
  line-height: unset;
}
.date-label h6:first-child{
  font-size: 0.6rem;
}
.date-label h6:last-child{
  font-size: 1rem;
}
input[type="radio"]:checked + .date-type-label {
  /* 為了修飾存在的邊框背景屬性 */
  /* border-color: #cbcccc;
  border-bottom-color: #fff; */
  background-color: rgb(197, 229, 255);
  z-index: 2;
}

.big-number-row{
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: var(--xs);
}
.other-info-row{
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: var(--xs);
}

.stats-card{
  /* box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075); */
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.075);
  min-width: 60px;
  min-height: 60px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}
.show-address-wrap{
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: var(--border-radius);
  padding-left: var(--md);
  padding-right: var(--md);
}
.stats-card-title{
  height: 40%;
}
.stats-card-content{
  flex:1;
  display: flex;
  /* place-items: center; */
  place-items: center;
  justify-content: center;
}
.stats-card .numeric{
  font-family: "Lato",sans-serif;
}
.stats-card h6{
  text-align: center;
  block-size: fit-content;
}
.other-info-row .stats-card{
  min-height: 200px;
}
#payment-method-count, #subscription-detail{
  background-color: rgb(210, 233, 193);
}
#number-of-takeaway, #total-revenue, #average-order-size, #payment-method-amount{
  background-color: rgb(250, 236, 174);
}
#total-discount-given, #discount-percentage, #staff-name, #top5{
  background-color: rgb(255, 204, 197);
}
#total-revenue{
  position: relative;
}
#total-revenue > div{
  position: absolute;
  right: 0;
  top: -2px;
}
/* #subscription-detail{
  background-color: rgb(197, 229, 255);
} */
/* ---------------------------- */
.card-main{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: var(--xs);
}

.card-part-1{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr 2fr;
  column-gap: var(--xs);
}

.icon{
  /* background-color: rgb(231, 231, 231); */
  background-color: white;
  /* max-height: 170px; */
  width: 100%;
  height: 100%;
  
  /* justify-self: center; */
  align-self: flex-end;
  display: grid;
  border-radius: var(--border-radius);
  place-items: center;
  background-repeat:no-repeat;
  background-size:contain;
  background-position: center;
  /* object-fit: cover; */
}
.default-icon{
  background-color: rgb(231, 231, 231);
  width: 350px;
  height: 150px;
  align-self: flex-end;
  display: grid;
  border-radius: 1rem;
  place-items: center;
  background-repeat:no-repeat;
  background-size:contain;
}
.top5-sales{
  min-height: 250px;
}

.top5-title{
  display: grid;
  grid-template-columns: 15% auto 20%;
  padding-left: var(--xs);
  padding-right: var(--xs);
}
.top5-sales-row{
  display: grid;
  grid-template-columns: 15% auto 20%;
}


#show-subscription-detail{
  padding: 7px var(--sm);
  place-items: unset;
  justify-content: unset;

}

.plan-info-row p,
.plan-info-row h6{
  display: inline-block;
}
.plan-info-row .title{
  width: 100px;
}
.plan-info-row{
  margin-top: 8px;
}
.plan-info-row.first{
  margin-top: 0px;
}
.plan-info-row .card.title{
  font-size: 16px;
  font-weight: 400;
}

.plan-name{
  margin: 13px 0 8px 0;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.plan-name.first{
  margin-top: 0px;
}
.plan-name.second{
  margin-top: 10px;
}
.card-info{
  width: 100px;
}
.card-icon{
  margin-right: 6px;
}
.icon-loading{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #373d4a;
  box-sizing: border-box;
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  border: 2px solid #ddd;
  border-left-color: #009688;
  border-radius: 30px;
  animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
}