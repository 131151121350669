.confirm-backdrop{
    height: 100vh;
    width: 100vw;
    left: 50%;
    top: -20px;
    transform: translateX(-50%);
}

/* #confirm-backdrop{
    position: fixed;
    top:0;
    left: -14.5%;
} */
.modal-container h6{
    font-size: 0.8rem;
}
.modal-container h5{
    word-break: break-all;
    -ms-word-break: break-all;
    font-size: 0.9rem;
}
#confirm-changes{
    top: 35%;
    height: 25%;
    width: 400px;
    /* border: 1px solid grey; */
}

#confirm-changes-content{
    /* background-color: pink; */
    
    overflow-y: scroll;
    border-radius: unset;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
#confirm-changes-foot{
    padding-bottom: var(--md);
}