.billing-table{
    width: 100%;
    margin-top: 20px;
}
.billing-table th{
    font-weight: 500;
    border-bottom: 1px solid rgb(226, 226, 226);
    text-align: start;
    padding-bottom: 5px;
    font-size: 0.9rem;
}
.billing-table td{
    padding: 8px 0;
    font-size: 0.9rem;
    border-bottom: 1px solid rgb(226, 226, 226);
}
.billing-table a{
    text-decoration: underline #757575;
    text-underline-offset: 3px
}
/* .billing-table tr:not(.billing-table-header):hover{
    cursor: pointer;
    background-color: rgb(213, 228, 233);
} */