.step5-content-wrap{
    margin-top: 200px;
}
#delete-restaurant-btn{
    margin-top: 25px;
    place-self: center;
    width: 400px;
}
.delete-restaurant-warning{
    width: 400px;
    place-self: center;
}