.user-list{
    width: 100vw;
    height: 100vh;
    background-color: #F6F7F9;
    padding: 20px 0;
}
.user-list .table-container{
    min-height: 500px;
    width: 90%;
    margin: auto;
    padding: 20px 20px;
    background-color: white;
}
.user-table{
    margin: auto;
    width: 100%;
    /* min-height: 400px; */
}
.user-table th{
    font-weight: 500;
    border-bottom: 1px solid rgb(226, 226, 226);
    text-align: start;
    padding-bottom: 5px;
    font-size: 0.9rem;
}
.user-table td{
    padding: 5px 0;
    font-size: 0.9rem;
    border-bottom: 1px solid rgb(226, 226, 226);
}
.user-table tr:not(.user-table-header):hover{
    cursor: pointer;
    background-color: rgb(213, 228, 233);
}
