.manager-input-row{
    font: inherit;
    color: currentColor;
    width: 100%;
    /* border: 0; */
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    border: unset!important;
    /* padding: unset; */
}
.manager-table-wrap{
    height: 70vh;
    overflow-y: scroll;
}
.step3-table{
    width: 100%;
    /* max-height: 350px; */
    display: flex;
    flex-direction: column;
}
.step3 .mat-card{
    height: unset;
}
.step3-table h6{
    font-size: 0.95rem;
    font-weight: 400;
}
.table-title{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 80px; */
    grid-template-columns: 20% 20% 15% 20% 20% minmax(60px, 1fr);
    height: 50px;
}
.table-title h6{
    font-size: 0.8rem;
}
.table-row{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 80px; */
    grid-template-columns: 20% 20% 15% 20% 20% minmax(60px, 1fr);
    min-height: 60px;
}
.input-row{
    min-height: 40px;
    display: grid;
    grid-template-rows: 40px minmax(0px, 1fr);
}
.input-row .MuiInput-underline:before{
    width: 95%;
}
.input-row .MuiInput-underline:after{
    width: 95%;
}
.input-row-icon{
    font-size: 1.25rem;
}

.table-row .error-text{
    font-size: 0.65rem;
}
.manager-input-row.isDemo input{
    color: #555555;
}
.qr-code-wrap{
    /* flex: 1;
    align-items: center; */
    /* margin-top: 80px; */
    margin-top: 30px;
    display: grid;
    place-items: center;
}
.qr-code{
    height: 200px;
    width: 200px;
    background-size: contain;
    background-position: center;
}
.qr-code-container {
    display: inline-flex;
    flex-direction: column;
    padding: 0 15px;
    align-items: center;
}
.qr-code-container h5, 
.qr-code-container p{
    margin-left: 5px;
    text-align: center;
}
.qr-code-wrap h4{
    line-height: 3rem;
}
.qr-code-wrap h5{
    line-height: 2rem;
}
.download-on-appstore{
    height: 60px;
    width: 100px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fappstore.svg?alt=media&token=a5e9b5ba-6830-449e-b288-eea378fa3874");
}
.download-on-appstore:active,
.download-on-playstore:active{
    transform: scale(0.95);
}

.download-on-playstore{
    height: 60px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fplaystore.svg?alt=media&token=36de0cf6-fc5c-4205-bf89-d6bc5c1a2514");
}
