.edit-subscription-backdrop{
    height: 100vh;
    width: 100vw;
    left: 50%;
    top: -20px;
    transform: translateX(-50%);
}

/* #confirm-backdrop{
    position: fixed;
    top:0;
    left: -14.5%;
} */

#edit-subscription-container{
    top: 15%;
    height: 70%;
    width: 700px;
    /* border: 1px solid grey; */
}

.edit-subscription-wrap{
    padding: var(--sm);
}

.edit-subscription-wrap.edit-credit-card{
    height: auto;
    padding: var(--sm);
}

#confirm-changes-content{
    /* background-color: pink; */
    
    overflow-y: scroll;
    border-radius: unset;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
#confirm-changes-foot{
    padding-bottom: var(--md);
}
.disabled-card{
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;
    cursor: default;
    pointer-events: none;
}
.disabled-card h6, 
.disabled-card i,
.disabled-card p,
.disabled-card span{
    color: rgba(0, 0, 0, 0.26);
}
.disabled-card .highlight{
    color: rgba(0, 0, 0, 0.26);
}