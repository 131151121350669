@import url(https://fonts.googleapis.com/css?family=Lato:400,100,100italic,300,300italic,400italic,700italic,700,900italic,900);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* @import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,200,300,500,600,700,800,900); */

*{ /* Safari/Chrome, other WebKit */    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

*::-webkit-scrollbar {
    display: none;
}
*{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}
input[type="radio"] {
    -webkit-appearance: checkbox; /* Chrome, Safari, Opera */
    -moz-appearance: checkbox;    /* Firefox */
    -ms-appearance: checkbox;     /* not currently supported */
}
html{
    font-family: Inter,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;
}
body{
    color:  #373d4a;
}
textarea{
    outline: none;
    resize: none;
}
input{
    outline: none;
}
table, td, th {
    border-collapse: collapse;
}
*{
    --xxs: 0.2rem;
    --xs: 0.375rem;
    --sm: 0.75rem;
    --md: 1rem;
    --lg: 1.25rem;
    --warning-font: #c62828; 
    --primary-btn: #96d2dc;
    --light-grey: #606060;
    --success-font: #4dc628;
    --failure-font: #c62828;
    --border-radius: 4px;
    --box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
    /* c62828 */
}

.warning{
    color: var(--warning-font);
}

.my-outlined-btn{
    /* background-color: var(--primary-btn); */
    border-width: 2px!important;
}

.loading {
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    height: 30px;
    width: 30px;
    border: 2px solid #ddd;
    border-left-color: #009688;
    border-radius: 30px; /* border-radius: 50% */
    -webkit-animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
    animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
}
.relative-loading {
    position: relative;
    top: 50%;
    left: 50%;
    margin: -15px 0 0 -15px;
    height: 30px;
    width: 30px;
    border: 2px solid #ddd;
    border-left-color: #009688;
    border-radius: 30px; /* border-radius: 50% */
    -webkit-animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
    animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
}

@-webkit-keyframes animation-rotate {
    100% {
    -webkit-transform: rotate(360deg);
    }
}
@keyframes animation-rotate {
    100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
    }
}

.mat-button{
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: .25px;
    min-width: 60px;
    min-height: 40px;
    display: grid;
    place-items: center;
    color: rgba(0,0,0,.54);
    font-size: 0.9rem;
    font-weight: 500;
    cursor: pointer;
}

.mat-button:hover{
    background: rgba(0,0,0,.06);
}
.mat-button:active{
    background: rgba(0,0,0,.12);
}
.mat-warn{
    background-color: #d32f2f;
    color: white;
}
.mat-button-disable{
    padding-left: 16px;
    padding-right: 16px;
    border: 0;
    border-radius: 8px;
    text-transform: none;
    letter-spacing: .25px;
    min-width: 60px;
    min-height: 40px;
    display: grid;
    place-items: center;
    background-color: rgba(0,0,0,.06);
    box-shadow: none;
    color: rgba(0,0,0,.26);
    font-size: 0.9rem;
    font-weight: 500;
}
.mat-disable{
    background-color: rgba(0,0,0,.06);
    box-shadow: none;
    color: rgba(0,0,0,.26);
}
.mat-primary{
    background-color: #1a73e8;
    color: white;
}
.mat-primary:hover{
    background-color: #1765cc;
    color: white;
}
.mat-primary:active{
    background-color: #1457b0;
    color: white;
}
.mat-warn:hover{
    background-color: #ba2929;
}
.mat-warn:active{
    background-color: #a02424;
}
/* The Modal (background) */
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /* background-color: rgb(0,0,0);  */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

.dialog-backdrop {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}
.dialog-container {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    margin-top: 30%;
    /* left: 25%;
    top: 25%; */
    top:0;
    left: 0;
    right: 0;
    margin-left: auto; 
    margin-right: auto; 
    /* width: 300px; */
    max-width: 600px;
    overflow: auto; /* Enable scroll if needed */
  }
  @media screen and (max-width: 960px) {
    .dialog-container{
      max-width: 600px;
    }
  } 
  @media screen and (max-width: 633px) {
    .dialog-container{
      max-width: 500px;
    }
  }
  @media screen and (max-width: 575px) {
    .dialog-container{
      max-width: 90%;
    }
  } 
.dialog {
    border-radius: 16px;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
.dialog-head{
    padding: 1.3rem;
    width: 100%;
    height: 100%;
}
.dialog-warning{
    font-weight: 500;
    color: #c62828;
    background: #fbe9e7;
}


.dialog-warning :is(h6,h5){
    font-weight: 500;
    color: #c62828;
    background: #fbe9e7;
}
.dialog-warning h6{
    font-size: 0.9rem;
}

.dialog-content{
    padding: 1.3rem;
}

.dialog-content label{
    color: rgba(0,0,0,.54);
    font-size: 0.85rem;
}
.error-text{
    color: #c62828;
    font-size: 0.75rem;
    line-height: 1rem;
    position: relative;
}
.error-text i{
    font-size: 0.95rem;
}
.success-text{
    color: #20a02b;
    font-size: 0.75rem;
    line-height: 1rem;
    position: relative;
}
.success-text i{
    font-size: 0.95rem;
}
/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }
  
.close:hover, .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
.modal-head .head-btn{
    border-radius: 50%;
    height: 40px;
    width: 40px;
}
.center, .head-btn, .btn{
    display: grid;
    place-items: center;
}
.touch, .head-btn, .btn\:circle, .btn{
    cursor: pointer;
}
.untouch{
    cursor: unset;
}
.shadow{
    box-shadow: 0 8px 10px rgb(0 0 0 / 1%), 0 3px 7px -1px rgb(0 0 0 / 15%);
}
.invisible{
    visibility: hidden;
}
.shadow\:light{ 
    box-shadow: 15px 15px 35px -8px rgba(81,81,81,0.53);
}
.head-btn, .btn{
    min-height: 50px;
    min-width: 50px;
    font-size: 1.25rem;
}
.head-bar{
    min-height: 50px;
    max-height: 70px;
    width: 100%;
}
.btn\:circle{
    border-radius: 50%;
    font-size: 1.25rem;
}

.border\:left, .border\:all{
    border-left: 1px solid rgba(0,0,0,.2);
}
.border\:right, .border\:all{
    border-right: 1px solid rgba(0,0,0,.2);
}
.border\:top, .border\:all{
    border-top: 1px solid rgba(0,0,0,.2);
}
.border\:bottom, .border\:all{
    border-bottom: 1px solid rgba(0,0,0,.2);
}

.c\:row{
    display: flex;
    flex-direction: column;
}
.c\:between{
    justify-content:space-between;
}
.c\:evenly{
    justify-content:space-evenly;
}
.c\:around{
    justify-content: space-around;
}
.c\:col{
    display: flex;
}
.c\:center{
    justify-content: center;
}
.c\:left{
    justify-content: flex-start;
}
.c\:right{
    justify-content: flex-end;
}
.justify-content-start{
    justify-content: flex-start;
}
.justify-content-end{
    justify-content: flex-end;
}
.justify-content-center{
    justify-content: center;
}
.justify-self-start{
    justify-self: flex-start;
}
.justify-self-center{
    justify-self: center;
}
.justify-self-end{
    justify-self: flex-end;
}
.align-items-start{
    align-items: flex-start;
}
.align-items-end{
    align-items: flex-end;
}
.align-items-center{
    align-items: center;
}
.align-self-start{
    align-self: flex-start;
}
.align-self-end{
    align-self: flex-end;
}
.align-self-center{
    align-self: center;
}

.c\:middle{
    align-items: center;
}
.flex\:1{
    flex: 1 1;
}
.flex\:3{
    flex: 3 1;
}
.float\:left{
    float: left;
}
.float\:right{
    float: right;
}


.gap\:xs{
    grid-gap: .375rem;
    gap: .375rem;
}
.gap\:sm{
    grid-gap: .75rem;
    gap: .75rem;
}
.gap\:md{
    grid-gap: 1rem;
    gap: 1rem;
}
.gap\:lg{
    grid-gap: 1.25rem;
    gap: 1.25rem;
}

.mb\:xs, .my\:xs, .m\:xs{
    margin-bottom: .375rem;
}
.mb\:sm, .my\:sm, .m\:sm{
    margin-bottom: .75rem;
}
.mb\:md, .my\:md, .m\:md{
    margin-bottom: 1rem;
}
.mb\:lg, .my\:lg, .m\:lg{
    margin-bottom: 1.25rem;
}

.mt\:xs, .my\:xs, .m\:xs{
    margin-top: .375rem;
}
.mt\:sm, .my\:sm, .m\:sm{
    margin-top: .75rem;
}
.mt\:md, .my\:md, .m\:md{
    margin-top: 1rem;
}
.mt\:lg, .my\:lg, .m\:lg{
    margin-top: 1.25rem;
}

.mr\:xs, .mx\:xs, .m\:xs{
    margin-right: .375rem;
}
.mr\:sm, .mx\:sm, .m\:sm{
    margin-right: .75rem;
}
.mr\:md, .mx\:md, .m\:md{
    margin-right: 1rem;
}
.mr\:lg, .mx\:lg, .m\:lg{
    margin-right: 1.25rem;
}

.ml\:xs, .mx\:xs, .m\:xs{
    margin-left: .375rem;
}
.ml\:sm, .mx\:sm, .m\:sm{
    margin-left: .75rem;
}
.ml\:md, .mx\:md, .m\:md{
    margin-left: 1rem;
}
.ml\:lg, .mx\:lg, .m\:lg{
    margin-left: 1.25rem;
}

.pb\:xs, .py\:xs, .p\:xs{
    padding-bottom: .375rem;
}
.pb\:sm, .py\:sm, .p\:sm{
    padding-bottom: .75rem;
}
.pb\:md, .py\:md, .p\:md{
    padding-bottom: 1rem;
}
.pb\:lg, .py\:lg, .p\:lg{
    padding-bottom: 1.25rem;
}

.pt\:xs, .py\:xs, .p\:xs{
    padding-top: .375rem;
}
.pt\:sm, .py\:sm, .p\:sm{
    padding-top: .75rem;
}
.pt\:md, .py\:md, .p\:md{
    padding-top: 1rem;
}
.pt\:lg, .py\:lg, .p\:lg{
    padding-top: 1.25rem;
}

.pl\:xs, .px\:xs, .p\:xs{
    padding-left: .375rem;
}
.pl\:sm, .px\:sm, .p\:sm{
    padding-left: .75rem;
}
.pl\:md, .px\:md, .p\:md{
    padding-left: 1rem;
}
.pl\:lg, .px\:lg, .p\:lg{
    padding-left: 1.25rem;
}

.pr\:xs, .px\:xs, .p\:xs{
    padding-right: .375rem;
}
.pr\:sm, .px\:sm, .p\:sm{
    padding-right: .75rem;
}
.pr\:md, .px\:md, .p\:md{
    padding-right: 1rem;
}
.pr\:lg, .px\:lg, .p\:lg{
    padding-right: 1.25rem;
}

.rt\:xs, .r\:xs{
    border-top-left-radius: .375rem;
    border-top-right-radius: .375rem;
}
.rt\:sm, .r\:sm{
    border-top-left-radius: .75rem;
    border-top-right-radius: .75rem;
}
.rt\:md, .r\:md{
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}
.rt\:lg, .r\:lg{
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
}

.rb\:xs, .r\:xs{
    border-bottom-left-radius: .375rem;
    border-bottom-right-radius: .375rem;
}
.rb\:sm, .r\:sm{
    border-bottom-left-radius: .75rem;
    border-bottom-right-radius: .75rem;
}
.rb\:md, .r\:md{
    border-bottom-left-radius: 1rem;
    border-bottom-right-radius: 1rem;
}
.rb\:lg, .r\:lg{
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
}

.text\:center{
    vertical-align: center;
}


h1{
    font-size: 2.25rem;
    line-height: 3rem;
}
h2{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
h3{
    font-size: 1.5rem;
    line-height: 2rem;
}
h4{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
h5{
    font-size: 1.125rem;
    line-height: 1.75rem;
}
h6{
    font-size: 1rem;
    line-height: 1.5rem;
}
p{
    font-size: .75rem;
}

.f\:xs {
    font-size: 0.75rem;
    line-height: 1rem;
}
.f\:sm {
    font-size: 0.875rem;
    line-height: 1.25rem;
}
.f\:md{
    font-size: 1rem;
    line-height: 1.5rem;
}
.f\:medium{
    font-size: medium;
}
.f\:lg{
    font-size: 1.125rem;
    line-height: 1.75rem;
}
.f\:xl{
    font-size: 1.25rem;
    line-height: 1.75rem;
}
.f\:2xl{
    font-size: 1.5rem;
    line-height: 2rem;
}
.f\:3xl{
    font-size: 1.875rem;
    line-height: 2.25rem;
}
.f\:300{
    font-weight: 300;
}
.f\:400{
    font-weight: 400;
}
.f\:500{
    font-weight: 500;
}

.f\:600{
    font-weight: 600;
}
.f\:700{
    font-weight: 700;
}
.f\:bold{
    font-weight: bold;
}
.f\:grey{
    color: #555555;
}
.f\:black{
    color: rgb(30, 30, 30);
}
.f\:light-grey{
    color: var(--light-grey);
}
.f\:white{
    color: white;
}
.f\:orange{
    color: #ff504a;
}
.f\:icon {
    font-size: 1.25rem;
}
.bg{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}
.bg\:head-bar{
    background-color: rgba(255,255,255,.85);
}
.bg\:overlay{
    background-color: rgba(0,0,0,.5);
}
.bg\:trans{
    background-color: transparent;
}
.bg\:black{
    background-color: black;
}
.bg\:grey{
    background-color: #eef2f5;
}
.bg\:white{
    background-color: white;
}
.bg\:pink{
    background-color: pink;
}
.bg\:green{
    background-color: green;
}
.bg\:yellow{
    background-color: #ffaa32;
}
.bg\:orange{
    background-color: #ff504a;
}

.min-h\:vh{
    min-height: 100vh;
}
.h\:full{
    height: 100%;
}
.h\:min{
    min-height: 100vh;
}
.h\:auto{
    height: auto;
}
.h\:inherit{
    height: inherit;
}
.w\:max{
    max-width: 768px;
}
.min-w\:vw{
    width: 100vw;
}
.w\:min{
    min-width: 100vw;
}
.w\:full{
    width: 100%;
}
.w\:auto{
    width: auto;
}
.w\:inherit{
    width: inherit;
}
.w\:4\/5{
    width: 80%;
}
.w\:3\/4{
    width: 75%;
}
.w\:1\/2{
    width: 50%;
}
.w\:1\/3{
    width: 66.666667%;
}
.w\:1\/4{
    width: 25%;
}
.max-w\:1\/2{
    max-width: 50%;
}
.max-w\:1\/4{
    max-width: 25%;
}
h1, h2, h3, h4, h5, h6, p{
    color: #555555;
    font-weight: 500;
    margin: 0;
}

a{
    text-decoration: none;
    color: #373d4a;
}
a:link {
    color: #373d4a;
}
/* visited link */
a:visited {
    color: #373d4a;
}
/* mouse over link */
a:hover {
    color: grey;
}
/* selected link */
a:active {
    color: #373d4a;
}

/* input[type=text], select {
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
} */


.form-input-wrap{
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 4px 0;
}
.form-input-wrap label{
    width: 140px;
    height: 40px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
}
.form-info-wrap{
    width: 100%;
    margin-bottom: 25px;
    margin-top: 4px;
    display: flex;
    flex-direction: row;
}
.form-info-wrap label{
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
}
.form-info-wrap h6{
    line-height: unset;
    font-size: 0.9rem;
}
.normal-input-field-wrap{
    display: flex;
    flex-direction: column;
    flex: 1 1;
}
.normal-input-field{
    width: 100%;
    /* justify-self: right; */

    padding: 10px 14px;
    font-size: 0.9rem;
    display: block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
input.normal-input-field{
    height: 40px;
}
.normal-input-error{
    height: 25px;
}
::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A5A5A5;
  }
:-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A5A5A5;
  }
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A5A5A5;
  }
  
:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A5A5A5;
}

::-ms-input-placeholder { /* Microsoft Edge */
    color: #A5A5A5;
}
input[type=submit] {
    width: 100%;
    background-color: #4CAF50;
    color: white;
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}
  
input[type=submit]:hover {
    background-color: #45a049;
}

.button{
    cursor: pointer;
    -ms-user-select: none;
        user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -o-user-select: none;
    cursor: pointer;
}
.button:hover{
    background-color: rgb(231, 231, 231);
}
.button:active{
    background-color: rgb(189, 189, 189);
}

.MuiButton-contained{
    /* box-shadow: var(--box-shadow); */
    box-shadow: unset;
}



.app-root{
  width: 100vw; 
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.app-bg-img{
  background-color: #1a73e8;
  height: 300px;
  width: 100%;
  position: absolute;
}
.caterat-logo-wrap{
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* height: 84px; */
  display: flex;
  align-items: center;
}
.app-bar{
  /* background-color: #b4d2eb; */
  background-image: linear-gradient(315deg, #3eadcf 74%,  #d7fceb 0%);
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 50px;
}
.app-bar-btn{
  width:80px;
  display: grid;
  justify-items: center;
  cursor: pointer;
  /* color: white; */
}
.app-bar-btn span{
  display: block;
  font-size: 0.8rem;
  color: white;
  /* color: var(--light-grey); */
}
.app{
  /* overflow-x: visible; */
  width: 95%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /* top: 50px; */
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  /* display: flex;
  flex-direction: column; */
}
.app-foot{
  position: relative;
  bottom: 0;
  right: 0;
  /* height: 10px; */
}
.target-email{
  display: grid;
  place-items: center;
  color: white;
}

.app-body{
  min-height: calc(100vh - 50px - 20px);
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eef2f5;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(3, 305px);
  grid-template-rows: 200px; */
  grid-row-gap: var(--sm);
  row-gap: var(--sm);
  grid-column-gap: 22.5px;
  -webkit-column-gap: 22.5px;
          column-gap: 22.5px;
}
  
.absolute-center{
  position: absolute;
  left: 50%;
  top: 25%;
}
.init-restaurant-sentence{
  position: relative; 
  left: -50%;
  z-index: 1;
  /* top: -25%; */
}

.init-restaurant-sentence a{
  color: rgb(122, 191, 255);
}
.init-restaurant-sentence a:hover{
  cursor: pointer;
  color: rgb(43, 152, 255);
}
#create-restaurant-card{
  /* flex-basis: 80px;
  flex-grow: 200px;
  min-height: unset; */
  /* height: 80px; */
}
#create-restaurant-card{
  /* height: 200px; */
  border-radius: var(--xs);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
  transition: 0.3s;
  place-items: center;
}
#create-restaurant-card:hover{
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

footer{
  /* position: relative; */
  bottom: 0;
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
footer a{
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}
footer a:not(:last-child)::after {
  content: "．";
}
footer a:hover{
  color: grey;
}
.modal-backdrop {
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 4; /* Sit on top */
    /* padding-top: 100px;  */
    left: 50%;
    top: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); 
}
.modal-container {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    border-radius: 16px;
    /* margin-top: 30%; */
    /* left: 25%;
    top: 25%; */
    top: 20px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 90%;
    /* height: calc(93vh - 20px); */
    height: 95vh;
    max-width: 1000px;
  }
/*   
  @media screen and (max-width: 960px) {
    .modal-container{
      max-width: 750px;
    }
  }  */
  @media screen and (max-width: 633px) {
    .modal-container{
      /* max-width: 600px; */
      width: 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 575px) {
    .modal-container{
      /* max-width: 90%; */
      width: 100%;
      max-width: 100%;
    }
  } 
.modal-control-bar{
    height: 45px;
}
.modal-close-btn{
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fefefe;
}
.modal-wrap {
    border-radius: 16px;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
  }
.modal-head{
    /* padding: 1.3rem; */
    width: 100%;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
}
.modal-content {
    overflow: scroll;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    /* background-color: pink; */
    width: 100%;
    flex: 2 1;
    /* height: calc(90vh - 20px - 70px); */
    /* overflow-y: scroll; */
    border-radius: 16px;
    /* border: 1px solid #888; */
    /* width: 80%; */
    /* overflow-y: scroll; */
  }
.tab-wrap input[type="radio"] {
    position: absolute;
    opacity: 0;
}
input[type="radio"]:checked + .tab-label {
    /* 為了修飾存在的邊框背景屬性 */
    border-color: #cbcccc;
    border-bottom-color: #fff;
    background: #fff;
    /* 為了修飾存在的層級使下邊框遮擋下方div的上邊框 */
    z-index: 10;
}

.tab-label {
    /* border-radius: 16px 16px 0px 0px; */
    width: 100%;
    height: 50px;
    cursor: pointer;
    /* background: -webkit-linear-gradient(#666, #555); */
    background-color: #b4d2eb;
    color: black;
    float: left;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    /* display: relative; */
    font-size: 0.9rem;
    margin-right: 2px;
    /* font: italic 1em cambria; */
}

.tab-label.first-label{
    border-radius: 16px 0px 0px 0px;
}
.tab-label.last-label{
    border-radius: 0px 16px 0px 0px;
}
.tab-label:hover {
    background: rgb(214, 214, 214);
}

/* .step-btn {
    width: 100px;
    height: 50px;
} */
.save-btn, .step-btn {
    width: 150px;
    height: 50px;
}
.step-btn h6{
    color: #3f51b5;
}
.btn h6{
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}




*{
    --checkbox-wrap-height: 25px;
    --checkbox-wrap-width: 200px;
}

.normal-input-hints{
    font-size: 0.8rem;
    color: #373d4a;
    margin-top: 4px;
}
#address1, #address2, #address3{
    flex: unset;
    width: 70%;
}
.step1 #step1-card{
    width: 400px;
    height: 230px;
    border: 1px solid rgba(0,0,0,.12);
}
@media screen and (max-width: 633px) {
    .step1 #step1-card{
        width: 300px;
    }
}
.step1{
    /* height: -moz-calc(80vh);
    height: -webkit-calc(80vh);
    height: calc(80vh); */
    overflow-y: scroll;
}
.basic-info-form-body{
    overflow-y: scroll;
}
.checkbox-grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: var(--xs);
    row-gap: var(--xs);
}
.checkbox-wrap{
    display: flex;
    grid-column-gap: var(--xs);
    -webkit-column-gap: var(--xs);
            column-gap: var(--xs);
    margin-right: var(--xxs);
    margin-left: var(--xxs);
    height: 25px;
    width: 100%;
    place-items: center;
}
.checkbox-wrap h6{
    line-height: unset;
    font-size: 0.9rem;
}
.checkbox{
    height: 14px;
    width: 14px;
}
.checkbox-delete-btn, .checkbox-add-btn{
    display: grid;
    place-items: center;
    font-size: 21px;
}
.checkbox-input-field{
    /* margin-top: var(--xxs); */
    width: 150px;
    height: 25px;
    padding: 12px 10px;
    /* margin: 8px 0; */
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.confirm-backdrop{
    height: 100vh;
    width: 100vw;
    left: 50%;
    top: -20px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

/* #confirm-backdrop{
    position: fixed;
    top:0;
    left: -14.5%;
} */
.modal-container h6{
    font-size: 0.8rem;
}
.modal-container h5{
    word-break: break-all;
    -ms-word-break: break-all;
    font-size: 0.9rem;
}
#confirm-changes{
    top: 35%;
    height: 25%;
    width: 400px;
    /* border: 1px solid grey; */
}

#confirm-changes-content{
    /* background-color: pink; */
    
    overflow-y: scroll;
    border-radius: unset;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
#confirm-changes-foot{
    padding-bottom: var(--md);
}
.credit-card-input-wrap{
    width: 100%;
    padding: 12px 20px;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}
.select-plan-btn{
    place-self: center;
    width: 400px;
    /* margin-bottom: 25px; */
}
.show-plan-wrap{
    margin-bottom: 25px;
    grid-gap: 40px;
    gap: 40px;
    flex-direction: row-reverse;
}
.plan-card{
    width:300px;
    min-height: 180px;
    background-color: white;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    justify-content: center;
    /* box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075); */
    border-radius: var(--sm);
    transition: box-shadow .3s;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    text-align: center;
    cursor: pointer;
    padding: var(--sm);
}
.plan-card:hover{
    box-shadow: rgba(99, 99, 99, 0.2) 6px 6px 6px 2px;
}
.plan-card:active{
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}
.plan-card.chosen{
    background-color: #caffe7;
    background-image: linear-gradient(315deg, #b4ffddb2 0%, #7ce0ffd0 74%);
    /* color: rgb(238, 238, 238); */
    font-weight: bold;
}
.plan-card.chosen h6, .plan-card.chosen p{
    /* color: rgb(238, 238, 238); */
}
.plan-card .title{
    font-size: 1.2rem;
    line-height: 3rem;
}
/* .plan-card:hover > .plan-card-title{
    background-color: teal;
}
.plan-card:hover > .plan-card-title h6{
    color: #ffffff;
} */

.plan-card i{
    font-size: 2.5rem;
}
.plan-card p{
    font-size: 1rem;
    line-height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin: 0.2rem 0; */
}
.plan-card p>i{
    font-size: 1rem;
    margin-left: 0.3rem;
    line-height: 2rem;
    vertical-align: middle;
}
.plan-card-price{
    margin: 10px 0;
}
.plan-card-price h6{
    font-family: "Lato",sans-serif;
    letter-spacing: -2px;
    color: #525252;
    font-weight: 400;
    font-size: 1.4rem;
}

.plan-card .dollar-sign {
    display: inline-block;
    font-family: "Lato",sans-serif;
    font-size: 15px;
    font-weight: 500;
    vertical-align: top;
}
.plan-card .currency {
    font-family: "Lato",sans-serif;
    font-size: 30px;
    font-weight: 500;
    letter-spacing: -2px;
    line-height: 36px;
    padding: 0;
    vertical-align: middle;

}
.plan-card .highlight{
    color: #26a5ccd0;
}
.plan-card.chosen .highlight{
    color: unset;
}
.plan-card .year, .month, .interval{
    font-family: "Lato",sans-serif;
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 3px;
    vertical-align: bottom;
}
.pricing h2{
    color: #9A1452;
}
.plan-card-btn{
    margin: 20px 0;
    height: 50px;
    width: 150px;
    border-radius: var(--sm);
    background-color: #7f608b;
    color: white;
    font-size: 1.8rem;
}
.step2 a{
    text-decoration: underline;
    cursor: pointer;
}
.restaurant-card{
    width: 100%;
    /* height: 350px; */
    flex-basis: 200px;
    /* max-height: 100%; */
    position: relative;
    background-color: white;
    box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
    /* box-shadow: 0 2px 6px 0 #434e57; */
    transition: 0.3s;
    border-radius: var(--border-radius);
}
.restaurant-card h6{
  font-size: 0.8rem;
}
.restaurant-card-icon{
  font-size: 1.3rem;
}
.restaurant-edit-btn{
  /* margin-top: 4px; */
  border-radius: 50%;
  display: grid;
  place-items: center;
  width: 30px;
  height: 30px;
}

.earth-btn{
  display: grid;
  /* justify-items: center; */
  align-items: center;
}
.createdAt{
  font-size: 0.7rem;
  color: grey;
}
.card-restaurant-name{
  word-wrap:break-word;
}

.card-info-row{
  /* display: grid;
  grid-template-columns: auto minmax(0, 1fr); */
  display: grid;
  grid-template-columns: calc(50% - 30px) 1fr 30px;
}
.stats-info-row{
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  grid-column-gap: var(--xs);
  -webkit-column-gap: var(--xs);
          column-gap: var(--xs);
}
.stats-info-row.isDemo{
  grid-template-columns: 2fr 1fr;
}
.date-picker{
  height: 40px;
  display: grid;
  grid-template-columns: 40px 120px 120px 120px 40px;
  grid-column-gap: var(--xs);
  -webkit-column-gap: var(--xs);
          column-gap: var(--xs);
  align-items: center;
}
.date-picker-btn{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  /* background-color: thistle; */
}
.date-picker-btn:hover{
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
}
.date-btn{
  border: 1px solid rgb(32, 32, 32);
}

.date-type-btn{
  position: absolute;
  opacity: 0;
}
.date-type-label{
  /* align-self: center; */
  justify-self: center;
  width: 120px;
  height: 40px;
  cursor: pointer;
  border-radius: var(--border-radius);
  border: 2px solid rgb(197, 229, 255);
  color: black;
  font-size: 0.8rem;
  text-align: center;
  /* vertical-align: middle; */
  display: flex;
  /* grid-template-columns: 30% 1fr; */
  justify-content: space-evenly;
  place-items: center;
  overflow-y: hidden;
}

.date-label h6{
  display: grid;
  grid-template-rows: 30% 1fr;
  line-height: unset;
}
.date-label h6:first-child{
  font-size: 0.6rem;
}
.date-label h6:last-child{
  font-size: 1rem;
}
input[type="radio"]:checked + .date-type-label {
  /* 為了修飾存在的邊框背景屬性 */
  /* border-color: #cbcccc;
  border-bottom-color: #fff; */
  background-color: rgb(197, 229, 255);
  z-index: 2;
}

.big-number-row{
  display:grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: var(--xs);
  -webkit-column-gap: var(--xs);
          column-gap: var(--xs);
}
.other-info-row{
  display:grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: var(--xs);
  -webkit-column-gap: var(--xs);
          column-gap: var(--xs);
}

.stats-card{
  /* box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075); */
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.075);
  min-width: 60px;
  min-height: 60px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}
.show-address-wrap{
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: var(--border-radius);
  padding-left: var(--md);
  padding-right: var(--md);
}
.stats-card-title{
  height: 40%;
}
.stats-card-content{
  flex:1 1;
  display: flex;
  /* place-items: center; */
  place-items: center;
  justify-content: center;
}
.stats-card .numeric{
  font-family: "Lato",sans-serif;
}
.stats-card h6{
  text-align: center;
  block-size: -webkit-fit-content;
  block-size: -moz-fit-content;
  block-size: fit-content;
}
.other-info-row .stats-card{
  min-height: 200px;
}
#payment-method-count, #subscription-detail{
  background-color: rgb(210, 233, 193);
}
#number-of-takeaway, #total-revenue, #average-order-size, #payment-method-amount{
  background-color: rgb(250, 236, 174);
}
#total-discount-given, #discount-percentage, #staff-name, #top5{
  background-color: rgb(255, 204, 197);
}
#total-revenue{
  position: relative;
}
#total-revenue > div{
  position: absolute;
  right: 0;
  top: -2px;
}
/* #subscription-detail{
  background-color: rgb(197, 229, 255);
} */
/* ---------------------------- */
.card-main{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 3fr 1fr;
  grid-column-gap: var(--xs);
}

.card-part-1{
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 3fr 2fr;
  grid-column-gap: var(--xs);
  -webkit-column-gap: var(--xs);
          column-gap: var(--xs);
}

.icon{
  /* background-color: rgb(231, 231, 231); */
  background-color: white;
  /* max-height: 170px; */
  width: 100%;
  height: 100%;
  
  /* justify-self: center; */
  align-self: flex-end;
  display: grid;
  border-radius: var(--border-radius);
  place-items: center;
  background-repeat:no-repeat;
  background-size:contain;
  background-position: center;
  /* object-fit: cover; */
}
.default-icon{
  background-color: rgb(231, 231, 231);
  width: 350px;
  height: 150px;
  align-self: flex-end;
  display: grid;
  border-radius: 1rem;
  place-items: center;
  background-repeat:no-repeat;
  background-size:contain;
}
.top5-sales{
  min-height: 250px;
}

.top5-title{
  display: grid;
  grid-template-columns: 15% auto 20%;
  padding-left: var(--xs);
  padding-right: var(--xs);
}
.top5-sales-row{
  display: grid;
  grid-template-columns: 15% auto 20%;
}


#show-subscription-detail{
  padding: 7px var(--sm);
  place-items: unset;
  justify-content: unset;

}

.plan-info-row p,
.plan-info-row h6{
  display: inline-block;
}
.plan-info-row .title{
  width: 100px;
}
.plan-info-row{
  margin-top: 8px;
}
.plan-info-row.first{
  margin-top: 0px;
}
.plan-info-row .card.title{
  font-size: 16px;
  font-weight: 400;
}

.plan-name{
  margin: 13px 0 8px 0;
  text-decoration: underline;
  text-underline-offset: 3px;
}
.plan-name.first{
  margin-top: 0px;
}
.plan-name.second{
  margin-top: 10px;
}
.card-info{
  width: 100px;
}
.card-icon{
  margin-right: 6px;
}
.icon-loading{
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  color: #373d4a;
  box-sizing: border-box;
  position: relative;
  height: 20px;
  width: 20px;
  margin-left: 5px;
  border: 2px solid #ddd;
  border-left-color: #009688;
  border-radius: 30px;
  -webkit-animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
          animation: animation-rotate 950ms cubic-bezier(.64,2,.56,.6) infinite;
}
.edit-subscription-backdrop{
    height: 100vh;
    width: 100vw;
    left: 50%;
    top: -20px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}

/* #confirm-backdrop{
    position: fixed;
    top:0;
    left: -14.5%;
} */

#edit-subscription-container{
    top: 15%;
    height: 70%;
    width: 700px;
    /* border: 1px solid grey; */
}

.edit-subscription-wrap{
    padding: var(--sm);
}

.edit-subscription-wrap.edit-credit-card{
    height: auto;
    padding: var(--sm);
}

#confirm-changes-content{
    /* background-color: pink; */
    
    overflow-y: scroll;
    border-radius: unset;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
#confirm-changes-foot{
    padding-bottom: var(--md);
}
.disabled-card{
    background-color: rgba(0, 0, 0, 0.12);
    box-shadow: none;
    cursor: default;
    pointer-events: none;
}
.disabled-card h6, 
.disabled-card i,
.disabled-card p,
.disabled-card span{
    color: rgba(0, 0, 0, 0.26);
}
.disabled-card .highlight{
    color: rgba(0, 0, 0, 0.26);
}
.manager-input-row{
    font: inherit;
    color: currentColor;
    width: 100%;
    /* border: 0; */
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    -webkit-animation-name: mui-auto-fill-cancel;
            animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    -webkit-animation-duration: 10ms;
            animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    border: unset!important;
    /* padding: unset; */
}
.manager-table-wrap{
    height: 70vh;
    overflow-y: scroll;
}
.step3-table{
    width: 100%;
    /* max-height: 350px; */
    display: flex;
    flex-direction: column;
}
.step3 .mat-card{
    height: unset;
}
.step3-table h6{
    font-size: 0.95rem;
    font-weight: 400;
}
.table-title{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 80px; */
    grid-template-columns: 20% 20% 15% 20% 20% minmax(60px, 1fr);
    height: 50px;
}
.table-title h6{
    font-size: 0.8rem;
}
.table-row{
    display: grid;
    /* grid-template-columns: 1fr 1fr 1fr 80px; */
    grid-template-columns: 20% 20% 15% 20% 20% minmax(60px, 1fr);
    min-height: 60px;
}
.input-row{
    min-height: 40px;
    display: grid;
    grid-template-rows: 40px minmax(0px, 1fr);
}
.input-row .MuiInput-underline:before{
    width: 95%;
}
.input-row .MuiInput-underline:after{
    width: 95%;
}
.input-row-icon{
    font-size: 1.25rem;
}

.table-row .error-text{
    font-size: 0.65rem;
}
.manager-input-row.isDemo input{
    color: #555555;
}
.qr-code-wrap{
    /* flex: 1;
    align-items: center; */
    /* margin-top: 80px; */
    margin-top: 30px;
    display: grid;
    place-items: center;
}
.qr-code{
    height: 200px;
    width: 200px;
    background-size: contain;
    background-position: center;
}
.qr-code-container {
    display: inline-flex;
    flex-direction: column;
    padding: 0 15px;
    align-items: center;
}
.qr-code-container h5, 
.qr-code-container p{
    margin-left: 5px;
    text-align: center;
}
.qr-code-wrap h4{
    line-height: 3rem;
}
.qr-code-wrap h5{
    line-height: 2rem;
}
.download-on-appstore{
    height: 60px;
    width: 100px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fappstore.svg?alt=media&token=a5e9b5ba-6830-449e-b288-eea378fa3874");
}
.download-on-appstore:active,
.download-on-playstore:active{
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
}

.download-on-playstore{
    height: 60px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url("https://firebasestorage.googleapis.com/v0/b/caterat-pos.appspot.com/o/resources%2Fplaystore.svg?alt=media&token=36de0cf6-fc5c-4205-bf89-d6bc5c1a2514");
}

.stripe-key-input-wrap{
    /* background-color: pink; */
    /* position: fixed; */
    width: 100%;
}
.step4{
    /* height: -moz-calc(75vh);
    height: -webkit-calc(75vh);
    height: calc(75vh); */
    overflow-y:scroll;
}
.stripe-guide-form-body{
    height: 100%;
    border: 1px solid lightgray;
    padding-left: var(--xs);
    /* overflow: scroll; */
}
.stripe-guide{
    background-color: white;
    height: calc(45vh);
    /* background-color: grey; */
    overflow-y:scroll;
    /* overflow-x: unset; */
}
.new-tab-btn{
    vertical-align: middle;
}
.new-tab-btn:hover{
    background-color: rgb(238, 238, 238);
}   
.new-tab-btn:active{
    background-color: rgb(219, 219, 219);
}   
.web-order-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 4px 0;
}
.web-order-link label{
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
}
.web-order-link-reminder{
    margin-left: 140px;
    margin-bottom: 25px;
}
img{
    max-height: 100%;
    max-width: 100%;
}
.stripe-guide-img{
    /* justify-self: center; */
    margin-top: var(--md);
    margin-bottom: var(--md);
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    padding:4px;
    border: 1px solid lightgray;
}

.show-key{
    width: calc(100% - 150px);
    max-width: calc(1000px - 200px);
    word-wrap:break-word;
}
#enable-web-order-btn{
    margin-top: 25px;
    place-self: center;
    width: 400px;
}
.success-font{
    color: var(--success-font);
}
.failure-font{
    color: var(--failure-font);
}
#web-order-status{
    margin-bottom: 0px;
}
.form-info-wrap a{
    text-decoration: underline;
}
#qr-code-download{
    margin-top: 10px;
    align-self: center;
    font-size: 12.8px;
    text-decoration: underline;
    text-underline-offset: 2px;
}
.privacy{
    max-width:1000px;
    margin: auto;
    padding: 20px;
}
.privacy h1{
    margin: 20px 0;
    text-align: center;
    font-size: 2rem;
    color: black;
}
.privacy h2{
    margin: 30px 0;
    font-size: 1.5rem;
    color: black;
}
.privacy p{
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.8rem;
    
}
.privacy li p{
    position: relative;
    left: 5px;
}
.privacy a{
    text-decoration: underline;
}
#terms-and-conditions{
    height: 90vh;
    width: 600px;
    border: 1px solid grey;
}

#terms-content{
    /* background-color: pink; */
    
    overflow-y: scroll;
    border-radius: unset;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}
#terms-content span{
    font-size: 0.8rem;
}
#web-terms-confirmation{
    margin: 0px;
    margin-right: 5px;
}
.step5-content-wrap{
    margin-top: 200px;
}
#delete-restaurant-btn{
    margin-top: 25px;
    place-self: center;
    width: 400px;
}
.delete-restaurant-warning{
    width: 400px;
    place-self: center;
}
.billing-table{
    width: 100%;
    margin-top: 20px;
}
.billing-table th{
    font-weight: 500;
    border-bottom: 1px solid rgb(226, 226, 226);
    text-align: start;
    padding-bottom: 5px;
    font-size: 0.9rem;
}
.billing-table td{
    padding: 8px 0;
    font-size: 0.9rem;
    border-bottom: 1px solid rgb(226, 226, 226);
}
.billing-table a{
    -webkit-text-decoration: underline #757575;
            text-decoration: underline #757575;
    text-underline-offset: 3px
}
/* .billing-table tr:not(.billing-table-header):hover{
    cursor: pointer;
    background-color: rgb(213, 228, 233);
} */
.contact-case-wrap{
    margin-top: var(--sm);
    padding-top: var(--md);
    border-top: 1px solid rgb(194, 194, 194);
}
.contact-case-row{
    display: grid;
    /* grid-template-columns: 55% 30% 1fr; */
    grid-template-columns: 80% 1fr;
    grid-column-gap: var(--sm);
    -webkit-column-gap: var(--sm);
            column-gap: var(--sm);
}
.contact-case-row h6{
    font-size: 0.8rem;
}
.contact-case-card{
    display: flex;
    width: 100%;
    min-height: unset;
    height: 40px;
    background-color: white;
    box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);
    /* box-shadow: 0 2px 6px 0 #434e57; */
    transition: 0.3s;
    border-radius: 0.4rem;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--sm);
    padding-right: var(--sm);
}

.contact-case-card:hover{
    box-shadow: 0 4px 4px 0 rgba(0,0,0,0.2);
    cursor: pointer;
}
/* .date{
    font-family: "Poppins", monospace;
} */
.chat-modal{
    position: relative;
    overflow: hidden;
}
.chat-title{
    text-align: center;
    font-weight: 600;
    background-color: teal;
    color: white;
    padding: var(--xs);
}
.chat-convo-wrap{
    overflow-y: scroll;
}
.chat-row{
    display: grid;
    grid-template-columns: 60px 1fr 60px;
}
.chat-date{
    align-self: center;
    font-size: 0.7rem;
}
.chat-content{
    /* background-color: rgb(173, 226, 226); */
    /* background-color: rgb(231, 231, 231); */
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    word-break: break-all;
    height: -moz-fit-content;
    height: -webkit-fit-content;
    height: fit-content;
    border-radius: 1.2rem;
    padding: var(--sm);
}
.sized-chat-box{
    height: 265px;
}
.speaker-img{
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: thistle;
}
.chat-reply-wrap{
    position: absolute;
    border-radius: 0.8rem 0.8rem 0 0 ;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 265px;
    background-color: teal;
}


.chat-input-field:disabled{
    background-color: rgb(235, 235, 235);
}

.chat-input-field::-webkit-input-placeholder {
    font-size: 1rem;
    line-height: 2rem;
}

.chat-input-field:-ms-input-placeholder {
    font-size: 1rem;
    line-height: 2rem;
}

.chat-input-field::placeholder {
    font-size: 1rem;
    line-height: 2rem;
}
.app-login{
    width: 100vw; 
    height: 100vh;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.login-body{
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 960px;
  padding-bottom: 20px;
}
header.login {
  position: absolute;
  display: flex;
  align-items: center;
  top:0;
  left: 0;
  padding-left: 50px;
  height: 84px;
  /* line-height: 84px; */
}
.company-logo{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: 80px;
}
footer.login{
  position: fixed;
  left: 0;
  bottom: 0;
}

.login-header h2{
  font-weight: bold;
  /* color: black; */
  font-family: 'Montserrat', sans-serif;
  -webkit-margin-before: 0.67em;
          margin-block-start: 0.67em;
  -webkit-margin-after: 0.67em;
          margin-block-end: 0.67em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}
.login-card{
  /* height: 600px;
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  background-color: thistle; */
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  position: relative;
  overflow: hidden;
  max-width: 100%;
  min-height: 480px;
}
.login-card-left{
  background-color: #FFFFFF;
  display: flex;
  width:50%;
  /* flex-direction: column; */
  /* align-items: center; */
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 50px;
  text-align: center;
}
.login-card-right{
  width:50%;

  background-color: #abe9cd;
  background: -webkit-linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);
  background-image: linear-gradient(315deg, #abe9cd 0%, #3eadcf 74%);

  /* background: #FF416C;
  background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
  background: linear-gradient(to right, #FF4B2B, #FF416C); */
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 0 0;
  color: #FFFFFF;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  padding: 0 50px;
  text-align: center;
  /* transform: translateX(0);
  transition: transform 0.6s ease-in-out; */
}
.login-card-right h1, .login-card-right h6{
  font-weight: bold;
  color: #eee;
  font-family: 'Montserrat', sans-serif;
}
.login-card-right h1{
  -webkit-margin-before: 0.67em;
          margin-block-start: 0.67em;
  -webkit-margin-after: 0.67em;
          margin-block-end: 0.67em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
  font-weight: bold;
}
.login-card-title{
  color: black;
  font-weight: bold;
  margin: 0;
  display: block;
  font-size: 2em;
  -webkit-margin-before: 0.67em;
          margin-block-start: 0.67em;
  -webkit-margin-after: 0.67em;
          margin-block-end: 0.67em;
  -webkit-margin-start: 0px;
          margin-inline-start: 0px;
  -webkit-margin-end: 0px;
          margin-inline-end: 0px;
}
.login-input-field{
  border-radius: var(--border-radius);
  background-color: #eee;
  border: none;
  padding: 12px 15px;
  margin-top: 8px;
  width: 100%;
}
.login-input-error{
  align-self: flex-start;
  height: 22px;
}
.login-btn{
  height: 43px;
  min-width: 154px;
  position: relative;
  border-radius: 20px;
  border: 1px solid #3eadcf;
  background-color: #3eadcf;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 60px;
  margin-top: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  cursor: pointer;
}
#send-email-btn:disabled{
  color: rgba(0, 0, 0, 0.26);
  background-color: rgba(0, 0, 0, 0.12);
  border: unset;
  cursor: default;

}
.signup-btn{
  height: 43px;
  min-width: 154px;
  border-radius: 20px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  padding: 10px 60px;
  margin-top: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  cursor: pointer;
}
.link-wrap{
  margin: 14px 0;
}
.login-card-link{
  display: inline;
  color: #333;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}
.login-btn:active, .signup-btn:active {
	-webkit-transform: scale(0.95);
	        transform: scale(0.95);
}

.register-link:hover{
  color: rgb(69, 69, 207)
}
.register-link:active{
  color: rgb(0, 0, 121);
}
/* .app-login{
    width: 100vw; 
    height: 100vh;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  } */

.register-link:hover{
  color: rgb(69, 69, 207)
}
.register-link:active{
  color: rgb(0, 0, 121);
}

.register-btn{
  height: 43px;
  min-width: 154px;
  position: relative;
  border-radius: 20px;
  border: 1px solid #3eadcf;
  background-color: #3eadcf;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  padding: 0 60px;
  margin-top: 0;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: -webkit-transform 80ms ease-in;
  transition: transform 80ms ease-in;
  transition: transform 80ms ease-in, -webkit-transform 80ms ease-in;
  cursor: pointer;
}
.user-setting{
    width: 100vw; 
    height: 100vh;
}
.user-setting-appbar{
    position: fixed;
    height: 50px;
    background-color: #1a73e8;
    top: 0;
    right: 0;
}

.user-setting-body{
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 960px) {
    .user-setting-body{
        max-width: 800px;
    }
} 
@media screen and (max-width: 800px) {
    .user-setting-body{
        max-width: 90%;
    }
}
@media screen and (max-width: 575px) {
    .user-setting-body{
        min-width: 98%;
        /* margin-left: 20px;
        margin-right: 20px; */
    }
} 
.user-setting .mat-card{
    width:100%;
    height: unset;
}
.user-setting .mat-card .mat-card-head{
    height: 40px;
}
.user-setting .mat-card label{
    font-size: 0.9rem;
    line-height: 1.3rem;
    color: rgba(0,0,0,.54);
}
.user-setting .mat-card .input-field{
    flex: 3 1;
    padding: 0px;
}

.confirm-login-wrap {
    border-radius: 16px;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
}

.inline-btn{
    width:200px;
    /* height: 50px; */
}
.user-list{
    width: 100vw;
    height: 100vh;
    background-color: #F6F7F9;
    padding: 20px 0;
}
.user-list .table-container{
    min-height: 500px;
    width: 90%;
    margin: auto;
    padding: 20px 20px;
    background-color: white;
}
.user-table{
    margin: auto;
    width: 100%;
    /* min-height: 400px; */
}
.user-table th{
    font-weight: 500;
    border-bottom: 1px solid rgb(226, 226, 226);
    text-align: start;
    padding-bottom: 5px;
    font-size: 0.9rem;
}
.user-table td{
    padding: 5px 0;
    font-size: 0.9rem;
    border-bottom: 1px solid rgb(226, 226, 226);
}
.user-table tr:not(.user-table-header):hover{
    cursor: pointer;
    background-color: rgb(213, 228, 233);
}

