.modal-backdrop {
    display: none; /* Hidden by default */
    position: fixed;
    z-index: 4; /* Sit on top */
    /* padding-top: 100px;  */
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100vw; /* Full width */
    height: 100vh; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgba(0,0,0,0.4); 
}
.modal-container {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 4; /* Sit on top */
    border-radius: 16px;
    /* margin-top: 30%; */
    /* left: 25%;
    top: 25%; */
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
    /* height: calc(93vh - 20px); */
    height: 95vh;
    max-width: 1000px;
  }
/*   
  @media screen and (max-width: 960px) {
    .modal-container{
      max-width: 750px;
    }
  }  */
  @media screen and (max-width: 633px) {
    .modal-container{
      /* max-width: 600px; */
      width: 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 575px) {
    .modal-container{
      /* max-width: 90%; */
      width: 100%;
      max-width: 100%;
    }
  } 
.modal-control-bar{
    height: 45px;
}
.modal-close-btn{
    display: grid;
    place-items: center;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-color: #fefefe;
}
.modal-wrap {
    border-radius: 16px;
    background-color: #fefefe;
    width: 100%;
    height: 100%;
  }
.modal-head{
    /* padding: 1.3rem; */
    width: 100%;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    
}
.modal-content {
    overflow: scroll;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    /* background-color: pink; */
    width: 100%;
    flex: 2 1;
    /* height: calc(90vh - 20px - 70px); */
    /* overflow-y: scroll; */
    border-radius: 16px;
    /* border: 1px solid #888; */
    /* width: 80%; */
    /* overflow-y: scroll; */
  }
.tab-wrap input[type="radio"] {
    position: absolute;
    opacity: 0;
}
input[type="radio"]:checked + .tab-label {
    /* 為了修飾存在的邊框背景屬性 */
    border-color: #cbcccc;
    border-bottom-color: #fff;
    background: #fff;
    /* 為了修飾存在的層級使下邊框遮擋下方div的上邊框 */
    z-index: 10;
}

.tab-label {
    /* border-radius: 16px 16px 0px 0px; */
    width: 100%;
    height: 50px;
    cursor: pointer;
    /* background: -webkit-linear-gradient(#666, #555); */
    background-color: #b4d2eb;
    color: black;
    float: left;
    line-height: 50px;
    text-align: center;
    vertical-align: middle;
    /* display: relative; */
    font-size: 0.9rem;
    margin-right: 2px;
    /* font: italic 1em cambria; */
}

.tab-label.first-label{
    border-radius: 16px 0px 0px 0px;
}
.tab-label.last-label{
    border-radius: 0px 16px 0px 0px;
}
.tab-label:hover {
    background: rgb(214, 214, 214);
}

/* .step-btn {
    width: 100px;
    height: 50px;
} */
.save-btn, .step-btn {
    width: 150px;
    height: 50px;
}
.step-btn h6{
    color: #3f51b5;
}
.btn h6{
    font-size: 0.875rem;
    font-weight: 500;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
}



