.chat-modal{
    position: relative;
    overflow: hidden;
}
.chat-title{
    text-align: center;
    font-weight: 600;
    background-color: teal;
    color: white;
    padding: var(--xs);
}
.chat-convo-wrap{
    overflow-y: scroll;
}
.chat-row{
    display: grid;
    grid-template-columns: 60px 1fr 60px;
}
.chat-date{
    align-self: center;
    font-size: 0.7rem;
}
.chat-content{
    /* background-color: rgb(173, 226, 226); */
    /* background-color: rgb(231, 231, 231); */
    width: -moz-fit-content;
    width: fit-content;
    word-break: break-all;
    height: -moz-fit-content;
    height: fit-content;
    border-radius: 1.2rem;
    padding: var(--sm);
}
.sized-chat-box{
    height: 265px;
}
.speaker-img{
    vertical-align: middle;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: thistle;
}
.chat-reply-wrap{
    position: absolute;
    border-radius: 0.8rem 0.8rem 0 0 ;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 265px;
    background-color: teal;
}


.chat-input-field:disabled{
    background-color: rgb(235, 235, 235);
}

.chat-input-field::placeholder {
    font-size: 1rem;
    line-height: 2rem;
}