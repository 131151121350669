.app-root{
  width: 100vw; 
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}
.app-bg-img{
  background-color: #1a73e8;
  height: 300px;
  width: 100%;
  position: absolute;
}
.caterat-logo-wrap{
  padding-left: 25px;
  padding-top: 5px;
  padding-bottom: 5px;
  /* height: 84px; */
  display: flex;
  align-items: center;
}
.app-bar{
  /* background-color: #b4d2eb; */
  background-image: linear-gradient(315deg, #3eadcf 74%,  #d7fceb 0%);
  position: fixed;
  width: 100%;
  z-index: 3;
  height: 50px;
}
.app-bar-btn{
  width:80px;
  display: grid;
  justify-items: center;
  cursor: pointer;
  /* color: white; */
}
.app-bar-btn span{
  display: block;
  font-size: 0.8rem;
  color: white;
  /* color: var(--light-grey); */
}
.app{
  /* overflow-x: visible; */
  width: 95%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
  /* top: 50px; */
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  /* display: flex;
  flex-direction: column; */
}
.app-foot{
  position: relative;
  bottom: 0;
  right: 0;
  /* height: 10px; */
}
.target-email{
  display: grid;
  place-items: center;
  color: white;
}

.app-body{
  min-height: calc(100vh - 50px - 20px);
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #eef2f5;
  /* padding: 20px; */
  display: flex;
  flex-direction: column;
  /* grid-template-columns: repeat(3, 305px);
  grid-template-rows: 200px; */
  row-gap: var(--sm);
  column-gap: 22.5px;
}
  
.absolute-center{
  position: absolute;
  left: 50%;
  top: 25%;
}
.init-restaurant-sentence{
  position: relative; 
  left: -50%;
  z-index: 1;
  /* top: -25%; */
}

.init-restaurant-sentence a{
  color: rgb(122, 191, 255);
}
.init-restaurant-sentence a:hover{
  cursor: pointer;
  color: rgb(43, 152, 255);
}
#create-restaurant-card{
  /* flex-basis: 80px;
  flex-grow: 200px;
  min-height: unset; */
  /* height: 80px; */
}
#create-restaurant-card{
  /* height: 200px; */
  border-radius: var(--xs);
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  box-shadow: 0 0 10px -2px rgba(0, 0, 0, 0.075);
  transition: 0.3s;
  place-items: center;
}
#create-restaurant-card:hover{
  box-shadow: 0 0 10px 6px rgba(0, 0, 0, 0.075);
  cursor: pointer;
}

footer{
  /* position: relative; */
  bottom: 0;
  width: 100%;
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
footer a{
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
}
footer a:not(:last-child)::after {
  content: "．";
}
footer a:hover{
  color: grey;
}