/* .app-login{
    width: 100vw; 
    height: 100vh;
    max-width: 960px;
    margin-left: auto;
    margin-right: auto;
  } */

.register-link:hover{
  color: rgb(69, 69, 207)
}
.register-link:active{
  color: rgb(0, 0, 121);
}

.register-btn{
  height: 43px;
  min-width: 154px;
  position: relative;
  border-radius: 20px;
  border: 1px solid #3eadcf;
  background-color: #3eadcf;
  color: #FFFFFF;
  font-size: 15px;
  font-weight: bold;
  padding: 0 60px;
  margin-top: 0;
  margin-bottom: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
}