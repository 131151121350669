.stripe-key-input-wrap{
    /* background-color: pink; */
    /* position: fixed; */
    width: 100%;
}
.step4{
    /* height: -moz-calc(75vh);
    height: -webkit-calc(75vh);
    height: calc(75vh); */
    overflow-y:scroll;
}
.stripe-guide-form-body{
    height: 100%;
    border: 1px solid lightgray;
    padding-left: var(--xs);
    /* overflow: scroll; */
}
.stripe-guide{
    background-color: white;
    height: -moz-calc(45vh);
    height: -webkit-calc(45vh);
    height: calc(45vh);
    /* background-color: grey; */
    overflow-y:scroll;
    /* overflow-x: unset; */
}
.new-tab-btn{
    vertical-align: middle;
}
.new-tab-btn:hover{
    background-color: rgb(238, 238, 238);
}   
.new-tab-btn:active{
    background-color: rgb(219, 219, 219);
}   
.web-order-link {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin: 4px 0;
}
.web-order-link label{
    width: 140px;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
}
.web-order-link-reminder{
    margin-left: 140px;
    margin-bottom: 25px;
}
img{
    max-height: 100%;
    max-width: 100%;
}
.stripe-guide-img{
    /* justify-self: center; */
    margin-top: var(--md);
    margin-bottom: var(--md);
    margin-left: auto;
    margin-right: auto;
    max-width: 80%;
    padding:4px;
    border: 1px solid lightgray;
}

.show-key{
    width: calc(100% - 150px);
    max-width: calc(1000px - 200px);
    word-wrap:break-word;
}
#enable-web-order-btn{
    margin-top: 25px;
    place-self: center;
    width: 400px;
}
.success-font{
    color: var(--success-font);
}
.failure-font{
    color: var(--failure-font);
}
#web-order-status{
    margin-bottom: 0px;
}
.form-info-wrap a{
    text-decoration: underline;
}
#qr-code-download{
    margin-top: 10px;
    align-self: center;
    font-size: 12.8px;
    text-decoration: underline;
    text-underline-offset: 2px;
}